<template>

    <div class="w-full h-full px-3 py-4">

        <div class="absolute top-0 left-0 w-full h-20 z-20" 
        :class="{'mt-28': isVisible || searchInput, '-mt-20': !isVisible && !searchInput}"
        style="transition: all .3s">
            <div class="h-full w-full flex flex-col justify-end items-center p-2" :class="'bg-module-'+mode">
                <input type="text" v-model="searchInput" :placeholder="$t('buscarrepresentant')" class="w-full h-10 px-2 rounded-xl" :class="'bg-box-'+mode+' text-dfont-'+mode">
            </div>
        </div>

        <All :model="model" :immediate="true" :query="query" :config="config" v-slot="{data, loading}">

            <div v-if="!loading" class="h-auto" :class="{'pt-12': searchInput != ''}">

                <!-- <div class="h-auto w-full rounded-lg shadow_box flex flex-col py-2 mb-6" :class="'bg-module-'+mode">
                    <div class="h-16 flex flex-row">
                        <div class="h-full w-10/12 flex flex-row">
                            <div class="h-full w-3/12 flex flex-row justify-center items-center">
                                <div class="h-12 w-12 rounded-full overflow-hidden">
                                    <vue-initials-img :name="zone.Name" class="h-12 w-12 rounded-full"/>
                                </div>
                            </div>
                            <div class="h-full w-9/12 flex flex-col justify-center items-start truncate">
                                <span class="text-lg font-semibold truncate" :class="'text-dfont-'+mode">{{ $t('zone') }} {{ zone.Name || 'No Name' }}</span>
                            </div>
                        </div>
                        <div class="h-full w-2/12 flex flex-col justify-start items-end">
                            <i class="mdi mdi-dots-vertical text-gray text-xl" @click="goFilters()"></i>
                        </div>
                    </div>

                    <div class="h-auto px-4 mt-2 flex flex-row justify-start items-start">
                        <span class="text-sm text-darkgray mr-2">{{ $t('period') }}:
                            <span class="text-purple font-semibold">{{ period }}</span>
                        </span>
                        
                        <span v-if="periodo == 'Mensual'" class="text-sm text-gray">Mes: <span class="text-sm text-darkgray font-semibold capitalize">{{ monthName }}</span></span>
                    </div>
                </div> -->

                <!-- <div class="h-auto w-full rounded-lg shadow_box mb-6 flex flex-col p-2" :class="'bg-module-'+mode">
                    <span class="text-lg font-semibold" :class="'text-dfont-'+mode">{{ $t('representatives') }}</span>
                    <span class="text-sm text-gray">{{ $t('indicator') }}:
                        <span class="text-purple font-semibold">{{ translate(dimensionName) }}</span>
                    </span>
                    <div class="h-10 rounded-lg mt-2 flex flex-row" :class="'bg-box-'+mode">
                        <div class="h-full w-2/12 flex flex-row justify-center items-center">
                            <i class="mdi mdi-magnify text-gray text-xl"></i>
                        </div>
                        <input type="text" v-model="searchInput" class="w-10/12 h-full rounded-lg px-2" :class="'text-dfont-'+mode+' bg-box-'+mode">
                    </div>
                </div> -->

                <Intersect @enter="onEnter" @leave="onLeave" >
                    <div class="h-1"></div>
                </Intersect>
                
                <div v-if="filteredComercials(data) && filteredComercials(data).length > 0" class="h-auto">
                    <representant v-for="(el, index) in filteredComercials(data)" :key="index" :data="el" :comercial="comercial" :dimensionName="dimensionName" />
                </div>

                <div v-else class="h-32 flex mt-6 flex-col justify-center items-center">
                    <span :class="'text-font_gray-'+mode">{{ $t('noData') }}</span>
                </div>

            </div>

            <div v-else class="h-32 relative">
                <loader :loading="loading" />
            </div>

        </All>

    </div>

</template>

<script>
import { state, actions } from '@/store';
import solidgauge from '@/components/solidgauge.vue';
import donutchart from '@/components/donutchart.vue';
import { All } from '@/api/components';
import loader from '@/components/loader.vue';
import doubleChart from '@/components/doubleChart.vue';
import representant from '@/components/representant.vue';
import Intersect from 'vue-intersect';

    export default {
        name: 'representante',
        components: {
            solidgauge,
            donutchart,
            All,
            loader,
            doubleChart,
            representant,
            Intersect
        },
        data() {
            return {
                facturation: false,
                yearact: this.$moment().format('YYYY'),
                yearant: this.$moment().subtract(1, 'years').format('YYYY'),
                yearantant: this.$moment().subtract(2, 'years').format('YYYY'),
                yesterday: this.$moment().subtract(1, 'days').format("DD MMM 'YY"),
                month: this.$moment().format("MMM 'YY"),
                montprevyear: this.$moment().subtract(1, 'years').format("MMM 'YY"),
                searchInput: '',
                isVisible: false
            }
        },
        methods: {
            onLeave(){
                this.isVisible = true
            },
            onEnter(){
                this.isVisible = false
            },
            translate(word) {
                switch (word) {
                    case 'Precio medio':
                        return this.$t("averagePrice")
                        break;
                    case 'Metros cuadrados':
                        return this.$t("squareMeters")
                        break;
                    case 'Facturación':
                        return this.$t("billing")
                        break;
                    case 'Margen':
                        return this.$t("margin")
                        break;
                }
            },
            toTops() { this.$router.push('grouptops') },
            activeFacturation() { this.facturation = !this.facturation },
            toRepresentant(representante) {
                actions.setIdEmployeeSelected(representante.Id)
                actions.setEmployeeSelected(representante)
                this.$router.push({name: 'representante', params:{representant:representante.Id}})
            },
            goFilters() { this.$router.push({name:'otherfiltersrepresentant'}) },
            filteredComercials(data) {
                return Object.values(data).filter(post => {
                    return post['Name'].toLowerCase().includes(this.searchInput.toLowerCase())
                })
            }
        },
        computed: {
            monthName(){
                return this.$moment({M: state.month -1 }).format('MMMM')
            },
            user() { return state.user },
            config() {
                if (state.user.rol.Name != 'Comercial') {
                    return {
                        data: {
                            Company: state.idCompany,
                            SaleType: state.saleTypeSelected,
                            Zone: state.idZone,
                            Dimension: state.dimensionGroup,
                        }
                    }
                }
                else {
                    return {
                        data: {
                            Company: state.idCompany,
                            SaleType: state.saleTypeSelected,
                            Zone: state.idZone,
                            Dimension: state.dimensionGroup,
                            IdUser: state.user.id
                        }
                    }
                }
            },
            comercial() { return state.comercialSelected },
            model() { return 'RepresentantsZone' },
            query() {
                return {
                    period: state.period,
                    IndustrialGroup: state.industrialGroup
                }
            },
            dimensionName() { return state.dimensionName },
            unit() {
                let unit = '€'
                if (state.dimensionGroup == 1) { unit = 'm<sup>2</sup>' }
                if (state.dimensionGroup == 3) { unit = '%' }
                return unit
            },
            unit3() {
                let unit = '%'
                if (state.dimensionGroup == 2) { unit = '' }
                if (state.dimensionGroup == 3) { unit = '' }
                return unit
            },
            period() {
                switch(state.period) {
                    case 'year':
                        return this.$t("annual")
                        break;
                    case 'quarter':
                        return this.$t("quarterly")
                        break;
                    default:
                        return this.$t("monthly")
                }
                return state.period
            },
            activeperiod() {
                switch (state.period) {
                    case 'month':
                        return this.$moment().format("MMM 'YY")
                        break;
                    case 'quarter':
                        return 'trim. ' + this.$moment().format("Q 'YY")
                        break;
                    case 'year':
                        return this.$moment().format("YYYY")
                        break;
                }
            },
            prevPeriod() {
                switch (state.period) {
                    case 'month':
                        return this.$moment().subtract(1, 'years').format("MMM 'YY")
                        break;
                    case 'quarter':
                        return 'trim. ' + this.$moment().subtract(1, 'years').format("Q 'YY")
                        break;
                    case 'year':
                        return this.$moment().subtract(1, 'years').format("YYYY")
                        break;
                }
            },
            zone() { return state.zone },
            mode(){
                return state.mode
            }
        }
    }
</script>
