<template>

<div class="h-auto w-full mb-4 rounded-xl flex flex-col overflow-hidden shadow_box" :class="'bg-module-'+mode">
        
        <div class="h-12 flex flex-row justify-start items-center px-4" :class="'bg-box-'+mode" @click="toRepresentant(data)">

            <div class="h-8 w-8 mr-2 rounded-full flex flex-col justify-center items-center" :class="'bg-darkgreen-'+mode">
                <span class="text-white text-lg font-bold">{{ data.Name.substr(0,1) }}</span>
            </div>
            
            <span class="text-md font-bold leading-none truncate" :class="'text-dfont-'+mode">{{ data.Name }} <br>
            <span :class="'text-2xs font-medium text-darkgreen-' + mode">{{data.ClientType}}</span>
            </span>

            <i class="mdi mdi-arrow-right text-2xl text-red ml-auto"></i>
            
        </div>

        <div class="flex flex-1 min-h-0 flex-col py-2" :class="'bg-module-'+mode">

            <div v-if="!reducetype" class="h-auto px-2"  @click="toRepresentant(data)">

                <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('day')}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.Yesterday | reduceBigNumbers()}} <span v-html="unit"></span></span>
                </div>
                <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                    <span v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="text-xs" :class="'text-font_gray-'+mode">{{$t('accumulated')}}</span>
                    <span v-if="dimensionName == 'Precio medio'" class="text-xs" :class="'text-font_gray-'+mode">{{$t('averagePrice')}}</span>
                    <span v-if="dimensionName == 'Margen'" class="text-xs" :class="'text-font_gray-'+mode">{{$t('margin')}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.Month | reduceBigNumbers()}} <span v-html="unit"></span></span>
                </div>
                <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('deliveryNotes')}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.DeliveryNote | reduceBigNumbers()}} <span v-html="unit"></span></span>
                </div>
                <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('scope')}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.MonthPreview | reduceBigNumbers()}} <span v-html="unit"></span></span>
                </div>
                <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                    <span class="text-xs" :class="'text-font_gray-'+mode">Real {{yearant}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.PastYear | reduceBigNumbers()}} <span v-html="unit"></span></span>
                </div>
                <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('variation')}} {{periodo}}</span>
                    <span class="text-sm font-medium" :class="'text-dfont-'+mode"><span v-if="data.MonthVariation >= 0">+</span>{{data.MonthVariation | reduceBigNumbers(2)}} <span v-html="unit3"></span></span>
                </div>

            </div>

            <div v-else class="h-auto px-2">

                <div class="h-auto flex flex-row justify-between items-center px-2"   @click="goToClient(data)">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('billing')}}</span>
                    <span v-if="data.Sale" class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.Sale | reduceBigNumbers()}} €</span>
                    <span v-else class="text-sm font-medium" :class="'text-dfont-'+mode">-</span>
                </div>

                <div class="h-auto flex flex-row justify-between items-center px-2"  @click="goToClient(data)">
                    <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('meters')}}</span>
                    <span v-if="data.Meters" class="text-sm font-medium" :class="'text-dfont-'+mode">{{data.Meters | reduceBigNumbers()}} m<sup>2</sup></span>
                    <span v-else class="text-sm font-medium" :class="'text-dfont-'+mode">-</span>
                </div>

                <div v-if="data.Latitude" class="h-auto w-full flex flex-row justify-center items-center p-2">

                    <div class="h-8 w-40 rounded-lg flex flex-row justify-center items-center border" :class="'border-dfont-'+mode">

                        <a v-if="data.Latitude" :href="'https://www.google.com/maps/search/?api=1&query=' + data.Latitude + ',' + data.Longitude + ''">
                            <span class="font-bold text-2xs" :class="'text-darkgreen-'+mode">{{$t('howToGetThere')}}</span>
                        </a>

                    </div>

                </div>

            </div>

        </div>
        
    </div>

    <!-- <div class="h-auto w-full rounded-lg shadow_box flex flex-col p-2 mb-6" :class="'bg-module-'+mode">
        <div class="h-16 flex flex-row px-2" @click="toRepresentant(data)">
            <div class="h-full w-2/12 flex flex-row justify-center items-center">
                <div class="h-12 w-12 rounded-full overflow-hidden">
                    <vue-initials-img :name="data.Name" class="h-12 w-12 rounded-full"/>
                </div>
            </div>
            <div class="h-full w-9/12 flex flex-col justify-center items-start pl-2 truncate">
                <span class="text-md font-semibold truncate" :class="'text-dfont-'+mode">{{data.Name || 'Nombre Representante desconocido'}}</span>
            </div>
            <div class="h-full w-1/12 flex flex-row justify-center items-center">
                <i class="mdi mdi-arrow-right font-semibold" :class="'text-dfont-'+mode"></i>
            </div>
        </div>

        <div class="flex-1 flex flex-col justify-between py-2" @click="toRepresentant(data)">
            <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-sm text-darkgray">{{$t('day')}}</span>
                <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{data.Yesterday | reduceBigNumbers()}} <span v-html="unit"></span></span>
            </div>
            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="text-sm text-darkgray">{{$t('accumulated')}}</span>
                <span v-if="dimensionName == 'Precio medio'" class="text-sm text-darkgray">{{$t('averagePrice')}}</span>
                <span v-if="dimensionName == 'Margen'" class="text-sm text-darkgray">{{$t('margin')}}</span>
                <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{data.Month | reduceBigNumbers()}} <span v-html="unit"></span></span>
            </div>
            <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-sm text-darkgray">{{$t('deliveryNotes')}}</span>
                <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{data.DeliveryNote | reduceBigNumbers()}} <span v-html="unit"></span></span>
            </div>
            <div v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span v-if="dimensionName == 'Facturación' || dimensionName == 'Metros cuadrados'" class="text-sm text-darkgray">{{$t('scope')}}</span>
                <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{data.MonthPreview | reduceBigNumbers()}} <span v-html="unit"></span></span>
            </div>
            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-sm text-darkgray">Real {{yearant}}</span>
                <span class="text-sm font-bold" :class="'text-dfont-'+mode">{{data.PastYear | reduceBigNumbers()}} <span v-html="unit"></span></span>
            </div>
            <div class="h-auto w-full flex flex-row justify-between items-center px-2 mb-2">
                <span class="text-sm text-darkgray">{{$t('variation')}} {{periodo}}</span>
                <span class="text-sm font-bold" :class="'text-dfont-'+mode"><span v-if="data.MonthVariation >= 0">+</span>{{data.MonthVariation | reduceBigNumbers(2)}} <span v-html="unit3"></span></span>
            </div>
        </div>

        <div v-if="permisions" class="h-auto">
            <Request model="Comercialtorepresentantmessage" action="create" :form="form" @success="onSuccess" @error="onError" v-slot="{ request, loading:loadingrequest }" class="w-full">
                <div class="h-auto">
                    <div class="h-10 flex flex-row justify-start items-center px-4">
                        <div class="h-8 flex-1 pr-2" @keypress.enter="request">
                            <input ref="input" type="text" v-model="message" :placeholder="$t('writeMessage')"  class="w-full h-full rounded-lg px-2 shadow-inner" :class="'text-dfont-'+mode+' bg-box-'+mode">
                        </div>
                        <div @click="request" class="h-8 w-10 rounded-lg bg-red flex flex-row justify-center items-center">
                            <i class="mdi mdi-send text-white"></i>
                        </div>
                    </div>
                </div>
            </Request>
        </div>
    </div> -->

</template>

<script>
import { state, actions } from '@/store';
import { Request } from '@/api/components';

export default {
    props: ['data', 'comercial', 'dimensionName'],
    components: {
        Request
    },
    data() {
        return {
            yearant: this.$moment().subtract(1, 'years').format('YYYY'),
            message: ''
        }
    },
    methods: {
        toRepresentant(representante) {
            actions.setIdEmployeeSelected(representante.Id)
            actions.setEmployeeSelected(representante)
            this.$router.push({name: 'representante', params:{representant:representante.Id}})
        },
        onSuccess() {
            this.message = null
            this.$message({
            message: this.$t("messageSentSuccessfully"),
            type: 'success'
            });
        },
        onError(error) {
            this.$message.error(this.$t("theMessageCouldNotBeSent"))
        }
    },
    computed: {
        unit() {
            let unit = '€'
            if (state.dimensionGroup === 1) { unit = 'm<sup>2</sup>' }
            if (state.dimensionGroup === 3) { unit = '%' }
            return unit
        },
        unit3() {
            let unit = '%'
            if (state.dimensionGroup === 2) { unit = ' €/m<sup>2</sup>' }
            if (state.dimensionGroup === 3) { unit = '' }
            return unit
        },
        form() {
            return{
                message: this.message,
                Company: state.idCompany,
                SaleType: state.saleTypeSelected,
                Zone: state.idZone,
                Employee: this.data.Id
            }
        },
        user() {
            return state.user
        },
        rol() {
            return state.user.rol.Name
        },
        permisions() {
            if (state.user.rol.Name === 'Comercial') { return true }
            else { return false }
        },
        zone() {
            return state.zone
        },
        mode(){
            return state.mode
        }
    }
}
</script>
